// //*for live api use uncomment============>>>>/*
export const environment = {
  production: true,
  apiUrl:'https://partnerapi.fareboutique.com/v1/',
  url:'https://partnerapi.fareboutique.com/',
  imageurl:'https://api.fareboutique.com/uploads/airlines/',
  xapikey:'1WBXEA1677145066445'
};


// //*for Dev api use uncomment============>>>>*/
// export const environment = {
//   production: true,
//   apiUrl:'https://devpatnerapi.fareboutique.com/v1/',
//   url:'https://devpatnerapi.fareboutique.com/',
//   imageurl:'https://devapi.fareboutique.com/uploads/airlines/',
//   xapikey:'1FMQKB1639407126571'
// };